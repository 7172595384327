import React from 'react';

interface PhoneMockupProps {
  title: string;
  src: string;
}

const PhoneMockup: React.FC<PhoneMockupProps> = ({ title, src }) => (
  <div className="w-48 h-96 rounded-3xl p-2 flex-shrink-0">
    <img src={src} alt={title} className="w-full" />
    <div className="w-full flex flex-col items-center justify-center">
      <p className="text-sm font-semibold mt-2">{title}</p>
    </div>
  </div>
);

export default PhoneMockup;
