import React, { ReactNode } from 'react';

interface FeatureCardProps {
  icon: ReactNode;
  title: string;
  description: string;
  solution: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ icon, title, description, solution }) => (
  <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-200">
    <div className="flex items-center mb-4">
      {icon}
      <h4 className="text-xl font-semibold ml-4">{title}</h4>
    </div>
    <p className="text-gray-600 italic mb-4">"{description}"</p>
    <p className="text-gray-800">{solution}</p>
  </div>
);

export default FeatureCard;
