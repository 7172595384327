import React, { useState, useEffect, useRef } from 'react';
import { Users, TrendingUp, BookOpen, Clock } from 'lucide-react';
import PhoneMockup from './components/PhoneMockup';
import FeatureCard from './components/FeatureCard';

function App() {
  const [mainMessage, setMainMessage] = useState('선택의 지혜');
  const messages = ['선택의 지혜', '운명의 실마리', '인생의 나침반', '삶의 로드맵', '숨겨진 가능성'];
  const [currentRollingIndex, setCurrentRollingIndex] = useState(0);
  const rollingMessages = [
    "\"미래가 불안해요. 사주를 보면 조금이라도 마음의 위안을 얻을 수 있을 것 같아요.\"",
    "\"나는 어떤 사람일까? 내 장단점을 객관적으로 알고 싶어요.\"",
    "\"중요한 선택을 앞두고 있는데, 어떤 결정을 내려야 할지 모르겠어요.\"",
    "\"저는 사주를 재미로 봐요. 좋은 것만 취하고 싶어요.\"",
    "\"과학으로 설명되지 않는 것들이 있잖아요. 다른 관점에서 바라보는 삶에 호기심이 생겨요.\"",
    "\"내가 가고 있는 방향이 맞는 건지 확신이 서지 않아요.\"",
    "\"이 관계를 어떻게 대처해야 할지 힌트를 얻고 싶어요.\"",
    "\"요즘 자신감이 많이 떨어졌어요. 사주를 보면 제 강점을 알고 다시 힘을 낼 수 있을 것 같아요.\""
  ];
  const mockupsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const changeMessage = setInterval(() => {
      setMainMessage(prevMessage => {
        const currentIndex = messages.indexOf(prevMessage);
        return messages[(currentIndex + 1) % messages.length];
      });
    }, 3000);

    const changeRollingMessage = setInterval(() => {
      setCurrentRollingIndex(prevIndex => (prevIndex + 1) % rollingMessages.length);
    }, 5000);

    let scrollInterval: NodeJS.Timeout;
    if (mockupsRef.current) {
      const scroll = () => {
        if (mockupsRef.current === null) {
          return;
        }
        if (mockupsRef.current.scrollLeft >= mockupsRef.current.scrollWidth / 2) {
          mockupsRef.current.scrollLeft = 0;
        } else {
          mockupsRef.current.scrollLeft += 1;
        }
      };
      scrollInterval = setInterval(scroll, 30);
    }

    return () => {
      clearInterval(changeMessage);
      clearInterval(changeRollingMessage);
      clearInterval(scrollInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="min-h-screen bg-white text-gray-800">
      <div className="relative">
        <div className="w-full h-screen bg-cover bg-center" style={{backgroundImage: "url('/waitlist_banner.jpg')"}}>
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative z-10 container mx-auto px-4 py-16 text-white text-left">
            <h1 className="text-4xl font-bold mb-8">마운틴뷰철학관</h1>
            <h2 className="text-3xl font-bold mb-6 leading-tight">
              미래가 불안한 우리에게,<br />
              사주가 제시하는<br />
              <span className="text-purple-300 transition-all duration-500">{mainMessage}</span>
            </h2>
            <a
              href="https://forms.gle/kwX2go5iJvbApYsVA" target="_blank" rel="noreferrer noopener"
               className="bg-gradient-to-r from-purple-500 to-indigo-600 text-white font-bold py-3 px-6 rounded-lg hover:opacity-90 transition duration-300 mb-12"
            >사전 예약하기</a>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 py-12">
        <div className="container mx-auto px-4">
          <h3 className="text-2xl font-semibold text-center mb-8">지금까지 본적없는 독창적 사주 분석</h3>
          <div ref={mockupsRef} className="flex overflow-x-hidden">
            <div className="flex space-x-8 animate-scroll">
              <PhoneMockup title="종합 운세" src="/screenshots/total.png" />
              <PhoneMockup title="반려동물 사주" src="/screenshots/pet.png" />
              <PhoneMockup title="기업 사주" src="/screenshots/company.png" />
              <PhoneMockup title="커리어 전략" src="/screenshots/career.png" />
              <PhoneMockup title="종합 운세" src="/screenshots/total.png" />
              <PhoneMockup title="반려동물 사주" src="/screenshots/pet.png" />
              <PhoneMockup title="기업 사주" src="/screenshots/company.png" />
              <PhoneMockup title="커리어 전략" src="/screenshots/career.png" />
            </div>
          </div>
        </div>
      </div>

      <main className="container mx-auto px-4 py-12">
        <div className="mb-16">
          <h3 className="text-2xl font-semibold text-center mb-6">
            사주는 이제, 현대사회의 트렌드가 됐습니다.
          </h3>
          <div className="bg-gray-100 p-6 rounded-lg shadow-inner">
            <p className="text-xl text-center font-semibold italic">
              {rollingMessages[currentRollingIndex]}
            </p>
          </div>
        </div>

        <div className="mb-16">
          <h3 className="text-xl font-semibold text-left mb-8 md:text-2xl ">
            당신의 삶에 새로운 통찰을 더하는 마운틴뷰철학관
          </h3>
          <p className="text-base mb-8 text-left leading-relaxed md:text-lg">
            내가 어떤 사람인지, 저 사람은 어떤 사람인지,<br />
            회사를 그만둘 지, 연애는 할 수 있을 지, 결혼을 해야 할 지…<br />
            크고 작은 고민과 결정 사이에서 번뇌하는 현대인들을 위해,<br />
            마운틴뷰 철학관은 가까이에서 편견없이 자세하게<br />
            선택지를 좁혀주고, 시야를 넓혀줄거에요.
          </p>
          <div className="space-y-8">
            <FeatureCard
              icon={<Users className="w-12 h-12 text-blue-500" />}
              title="편견이 없습니다"
              description="30대 여성이라고 하니까 결혼 얘기만 하더라고요. 제 커리어는 안중에도 없는 것 같았어요."
              solution="나이나 성별 때문에 편견을 가진 조언에 지치셨나요? 마운틴뷰 철학관은 사주 상담사의 주관적 해석이나 기분에 좌우되지 않습니다. 온전한 한 사람으로 바라봅니다. 나이와 성별의 굴레에서 벗어나세요. 꿈과 야망을 존중하는 분석을 지향합니다."
            />
            <FeatureCard
              icon={<TrendingUp className="w-12 h-12 text-green-500" />}
              title="상담사는 여러분의 직업을 잘 모를 수도 있습니다"
              description="개발자라고 하니까 사주 선생님이 잘 모르겠다고 하시더라고요. 제 직업에 맞는 조언을 듣고 싶은데 좀 답답했어요."
              solution="최신 직업 트렌드부터 전문 분야별 특성까지, 마운틴뷰 철학관은 사주 뿐만 아니라 커리어에 대한 깊이 있는 이해를 바탕으로 분석합니다. 어떤 분야라도 사주와 결합한 맞춤형 조언을 받을 수 있습니다."
            />
            <FeatureCard
              icon={<BookOpen className="w-12 h-12 text-yellow-500" />}
              title="독창적이면서 실용적입니다"
              description="해외 취업을 고민 중인데, 어느 나라가 저와 잘 맞을까요?"
              solution="전통 사주에서 다루기 어려웠던 현대적이고 구체적이며, 때로 사소하지만 재밌는 주제들에 대해서도 깊이 있는 분석과 실용적인 조언을 제공합니다. 일상과 밀접하게 연관된 크고 작은 영역에 대한 독창적인 재미와 지혜를 선보입니다."
            />
            <FeatureCard
              icon={<Clock className="w-12 h-12 text-red-500" />}
              title="언제든 불러만 주세요"
              description="밤에 갑자기 불안해서 사주가 궁금해졌는데, 이 시간에 볼 수 있는 곳이 없네요"
              solution="새벽 3시, 주말, 공휴일에도 마운틴뷰 철학관은 당신 곁에 있습니다. 불안하거나 궁금할 때 언제든 물어보세요."
            />
          </div>
        </div>
      </main>
      <footer className="bg-gray-100 text-center py-6">
        <p>&copy; 2024 마운틴뷰철학관. All rights reserved.</p>
      </footer>
    </div>
  );
};


export default App;
